<template>
  <div class="select-group select-group--mt" :style="getStyles">
    <el-form :key="keySelect" @submit.native.prevent>
      <el-row :gutter="24">
        <el-col :span="inline ? 12 : 24">
          <base-select
            v-if="allowSelectPartner"
            :label="$t('finances_pages.select.partner')"
            v-model="partnerOspId"
            :placeholder="$t('finances_pages.select.partner_placeholder')"
            :items="partnersList"
            :clearable="clearablePartner"
            filterable
            :required="partnerIsRequired"
            :validations="validationsPartner"
          >
            <template v-slot:option="{ items }">
              <el-option
                v-for="item in items"
                :key="item.extId"
                :value="item.ospId"
                :label="item.name"
              >
                <div class="filters__select-option">
                  <i class="ri-building-4-line"></i>
                  {{ item.name }}
                </div>
              </el-option>
            </template>
          </base-select>
        </el-col>
        <el-col :span="inline ? 12 : 24">
          <base-select
            v-if="allowSelectCA"
            :label="$t('finances_pages.select.corporate')"
            :key="keySelect"
            v-model="corporateOspId"
            :placeholder="$t('finances_pages.select.corporate_placeholder')"
            :items="corporateList"
            :clearable="clearableCA"
            filterable
            :required="corporateIsRequired"
            :validations="validationsCA"
            class="corporate-account-filter"
          >
            <template v-slot:option="{ items }">
              <el-option
                v-for="item in items"
                :key="item.extId"
                :value="item.ospId"
                :label="item.name"
              >
                <div class="filters__select-option">
                  <i class="ri-building-4-line"></i>
                  {{ item.name }}
                </div>
              </el-option>
            </template>
          </base-select>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { get, sortBy } from 'lodash'

import CorporateAccountRepository from '@/app/repositories/CorporateAccountRepository'

const corporateAccountRepository = new CorporateAccountRepository()

export default {
  name: 'PartnerCorporateAccountFilter',
  props: {
    allowSelectPartner: {
      type: Boolean,
      default: true
    },
    clearablePartner: {
      type: Boolean,
      default: false
    },
    clearableCA: {
      type: Boolean,
      default: false
    },
    allowSelectCA: {
      type: Boolean,
      default: true
    },
    partner: {
      type: String,
      default: ''
    },
    partnerIsRequired: {
      type: Boolean,
      default: false
    },
    corporateIsRequired: {
      type: Boolean,
      default: false
    },
    validationsPartner: {
      type: Object,
      default: null
    },
    validationsCA: {
      type: Object,
      default: null
    },
    inline: {
      type: Boolean,
      default: false
    },
    maxWidthOff: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      corporateList: [],
      partnerOspId: '',
      corporateOspId: '',
      defaultCorporateOspId: '',
      keySelect: ''
    }
  },
  computed: {
    ...mapGetters({
      allPartners: 'partner/allPartners',
      defaultCorporateAccount: 'corporateAccount/selectedCorporateAccount',
      selectedCorporateAccountOspId: 'corporateAccount/selectedCorporateAccountOspId'
    }),
    partnersList () {
      return sortBy(this.allPartners, item => item.name.toLowerCase())
    },
    filterCorporate () {
      return {
        active: true,
        pagination: {
          page: 0,
          size: 1000,
          sort: null
        },
        partnerOspId: {
          ospId: this.partnerOspId
        }
      }
    },
    getDefaultPartnerOspId () {
      return get(
        this.defaultCorporateAccount,
        ['corporateAccount', 'data', 'partner', 'ospId'],
        null
      )
    },
    getDefaultCorporateOspId () {
      if (this.$route.name === 'Invoices') {
        return this.selectedCorporateAccountOspId
      }
      return get(this.defaultCorporateAccount, 'ospId', null)
    },
    getDefaultCorporateName () {
      return this.selectedCorporateName ? this.selectedCorporateName : this.name
    },
    getStyles () {
      if (!this.allowSelectPartner && !this.allowSelectCA) {
        return 'display:none'
      }
      if (this.maxWidthOff) {
        return 'max-width: 100%'
      }
      return ''
    }
  },
  watch: {
    corporateOspId: {
      immediate: true,
      handler (v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    ...mapActions({
      fetchAllActivePartners: 'partner/fetchAllActivePartners'
    }),
    fetchCorporateAccounts () {
      return corporateAccountRepository.getByFilter(this.filterCorporate, {
        onSuccess: ({ content }) => {
          this.corporateList = sortBy(content, item => item.name.toLowerCase())
        }
      })
    }
  },
  async mounted () {
    if (this.visible || this.allowSelectCA || this.allowSelectPartner) {
      if (this.allowSelectPartner) {
        await this.fetchAllActivePartners()
      }
      this.partnerOspId = this.partner || this.getDefaultPartnerOspId
      if (this.partnerOspId) {
        this.$emit('update:partner', this.partnerOspId)
        await this.fetchCorporateAccounts()
      }
    }
    this.corporateOspId = this.corporateOspId || this.getDefaultCorporateOspId
    this.$emit('mounted')
    this.keySelect = this.$key()
    this.$watch('partnerOspId', v => {
      this.corporateOspId = null
      this.keySelect = this.$key()
      if (v) {
        this.fetchCorporateAccounts()
      }
      this.$emit('update:partner', v)
    })
  }
}
</script>
