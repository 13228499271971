<template>
<page-layout
 :title="$t('finance.company_private_budget_page.title')"
>
   <el-alert
      type="info"
      :closable="false"
      show-icon
    >
      <span v-html="$t('finance.company_private_budget_page.alert')"/>
    </el-alert>
    <div class="select-group select-group--mt">
        <el-form @submit.native.prevent>
         <base-select
           :label='$t("finances_pages.select.partner")'
           ref='partnerSelect'
           v-model="partnerSearch"
          :placeholder="$t('finances_pages.select.partner_placeholder')"
          :items="allPartners"
          :change="clearAndFetchPartnerData"
          filterable
          clearable
          >
          <template v-slot:option="{ items }">
                <el-option
                  v-for="item in items"
                  :key="item.extId"
                  :value="item.ospId"
                  :label="item.name"
                >
                  <div class="filters__select-option">
                    <i class="ri-building-4-line"></i>
                    {{ item.name }}
                  </div>
                </el-option>
              </template>
            </base-select>
          <base-select
          :label="$t('finances_pages.select.corporate')"
          ref='corporateSelect'
          v-model="corporateOspId"
          :key='keySelect'
          :placeholder="$t('finances_pages.select.corporate_placeholder')"
          :items="corporateList"
          :change='fetchPrivateBudget'
          filterable
          clearable

         >
         <template v-slot:option="{ items }">
                <el-option
                  v-for="item in items"
                  :key="item.extId"
                  :value="item.ospId"
                  :label="item.name"
                >
                  <div class="filters__select-option">
                    <i class="ri-building-4-line"></i>
                    {{ item.name }}
                  </div>
                </el-option>
              </template>
            </base-select>

          </el-form>
        </div>
        <base-section id='privateBudget'
        :title="$t('finance.company_private_budget_page.section')">
        <p> <span class="remaining-budget">€
          <span class="remaining-budget__amount">{{getPrivateCompanyBudget}}</span>
        </span></p>

        </base-section>

</page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'
import FinancePages from '@/mixins/FinancePages'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'CompanyPrivateBudget',
  components: {
    PageLayout
  },
  mixins: [FinancePages],
  computed: {
    ...mapGetters({
      getPrivateCompanyBudget: 'budget/getPrivateCompanyBudget'
    })
  },
  methods: {
    ...mapActions({
      getPrivateBudget: 'budget/getPrivateBudget'
    }),
    fetchPrivateBudget (id) {
      this.getPrivateBudget({ ospId: id || this.partnerSearch || 'OlympusMobility' })
    },
    clearAndFetchPartnerData (partner) {
      this.corporateOspId = ''
      this.defaultCorporateOspId = ''
      this.partnerSearch = partner
      this.keySelect = partner
      if (partner) {
        this.fetchCorporate()
      }
      this.getPrivateBudget({ ospId: partner || 'OlympusMobility' })
    }
  },
  mounted () {
    this.getPrivateBudget({ ospId: this.getDefaultCorporateOspId })
  }

}
</script>
