import Model from './Model'
import InvoiceRepository from '../repositories/InvoiceRepository'

class Invoice extends Model {
  #repository = null

  constructor (object = {}) {
    super(object)
    this.#repository = new InvoiceRepository()
  }

  downloadPdf () {
    return this.#repository.downloadInvoicePdf(this.ospId)
  }

  downloadXml () {
    return this.#repository.downloadInvoiceXml(this.ospId)
  }
}

export default Invoice
