<template>
  <page-layout :title="$t('finance.self_billing_invoices_page.title')">
    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('finance.self_billing_invoices_page.alert')" />
    </el-alert>
    <base-section class="filters">
      <div class="filters--content">
        <el-form label-position="top" :model="form" :inline="true">
          <base-datepicker
            required
            :label="$t('general.label.check_month')"
            type="month"
            placeholder="YYYY/MM"
            format="yyyy-MM"
            v-model="form.invoiceMonth"
            :validations="$v.form.invoiceMonth"
          />
        </el-form>
        <partner-corporate-account-filter
          allowSelectPartner
          partnerIsRequired
          :allowSelectCA="false"
          clearablePartner
          :partner.sync="form.mspExtId"
          :validationsPartner="$v.form.mspExtId"
        />
      </div>
    </base-section>
    <el-dropdown trigger="click" class="download-all" v-role="['ospadmin']">
      <base-button
        type="primary"
        append-icon="ri-arrow-down-s-line"
        prepend-icon="ri-download-2-line"
        :label="$t('general.label.download_all')"
      />
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="downloadXlsx">
          <i class="ri-download-2-line"></i>
          {{ $t('general.label.download') }} XLSX
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </page-layout>
</template>

<script>
import PageLayout from '@/layouts/PageLayout'
import InvoiceRepository from '@/app/repositories/InvoiceRepository'
import PartnerCorporateAccountFilter from '@/components/PartnerCorporateAccountFilter'
import { required } from 'vuelidate/lib/validators'
const invoiceRepository = new InvoiceRepository()

export default {
  name: 'SelfBillingInvoices',
  components: { PageLayout, PartnerCorporateAccountFilter },
  data () {
    return {
      form: {
        invoiceMonth: this.$date
          .local()
          .minus({ month: 1 })
          .toISODate(),
        mspExtId: 'Q8'
      }
    }
  },
  computed: {
    getForm () {
      const modifiedDate = this.form.invoiceMonth.replace(/-/g, '').slice(0, 6)
      const requestForm = { ...this.form, invoiceMonth: modifiedDate }
      return requestForm
    }
  },
  validations: {
    form: {
      invoiceMonth: {
        required
      },
      mspExtId: {
        required
      }
    }
  },
  methods: {
    downloadXlsx () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        invoiceRepository.downloadInvoiceXlsx(this.getForm)
      }
    }
  }
}
</script>

<style></style>
