var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-layout',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"title":_vm.$t('finance.transaction_details_page.title'),"has-back-button":true,"id":"transactionDetailsPage"}},[_c('el-form',{key:_vm.key,ref:"form",attrs:{"model":_vm.form,"id":"appUserExpenseForm","label-position":"top"},nativeOn:{"submit":function($event){$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('base-section',{attrs:{"title":_vm.$t('general.label.budget')}},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t('general.label.budget'),"value":_vm.summary.budgetName,"readonly":true}})],1)],1)],1),_c('base-section',{attrs:{"title":_vm.$t('general.label.price')}},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":12}},[_c('base-currency-input',{attrs:{"label":_vm.$t('general.label.price_vat_incl'),"value":_vm.price.priceVatIncl,"readonly":true}})],1),_c('el-col',{attrs:{"span":12}},[_c('base-currency-input',{attrs:{"label":_vm.$t('general.label.price_vat_excl'),"value":_vm.price.priceVatExcl,"readonly":true}})],1)],1),_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":12}},[_c('base-currency-input',{attrs:{"label":_vm.$t('general.label.price_vat'),"value":_vm.price.priceVat,"readonly":true}})],1),_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t(
                'user_management.view_app_users_page.add_expense.input_vat_perc.label'
              ),"value":(_vm.price.vatPercentage || 0) + '%',"readonly":true}})],1)],1),_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t(
                'finance.company_budget_page.dialog_promocode.input_promocode_label'
              ),"value":_vm.price.promoCode,"readonly":true}})],1),_c('el-col',{attrs:{"span":12}},[_c('base-currency-input',{attrs:{"label":_vm.$t('general.label.promocode_vat_incl'),"value":_vm.price.promoCodeValueVatIncl,"readonly":true}})],1)],1)],1),_c('base-section',{attrs:{"title":_vm.$t(
          'user_management.expenses_details_page.event_details_section.title'
        )}},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t(
                'partners_management.add_partner_page.partner_type_labels.MRP'
              ),"value":_vm.summary.mrpName,"readonly":true}})],1),_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t('general.label.resource'),"value":_vm.summary.resourceName,"readonly":true}})],1)],1),_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t(
                'user_management.expenses_details_page.event_details_section.event_time_label'
              ),"value":_vm.summary.createdOn &&
                _vm.$dateFormat(_vm.summary.eventTimestamp, 'yyyy-LL-dd hh:mm:ss'),"readonly":true}})],1),_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t(
                'user_management.expenses_details_page.event_details_section.event_created_on_label'
              ),"value":_vm.summary.createdOn &&
                _vm.$dateFormat(_vm.summary.createdOn, 'yyyy-LL-dd hh:mm:ss'),"readonly":true}})],1)],1),_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t('general.input_label.description'),"value":_vm.summary.description,"readonly":true}})],1),_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t(
                'user_management.expenses_details_page.event_details_section.booking_id_label'
              ),"value":_vm.summary.bookingId,"readonly":true}})],1)],1),_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t(
                'user_management.expenses_details_page.event_details_section.usage_reason_label'
              ),"value":_vm.summary.usageReason,"readonly":true}})],1),_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t(
                'user_management.expenses_details_page.event_details_section.external_reference_label'
              ),"value":_vm.summary.externalReference,"readonly":true}})],1)],1),_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":24}},[_c('base-input',{attrs:{"label":_vm.$t(
                'user_management.expenses_details_page.event_details_section.event_osp_id_label'
              ),"value":_vm.summary.eventOspId,"readonly":true}})],1)],1)],1),_c('base-section',{attrs:{"title":_vm.$t('user_management.expenses_details_page.user_details_section.title')}},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t('general.label.user'),"value":_vm.summary.userName,"readonly":true}})],1),_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t('corporate_accounts_management.view_all_page.title'),"value":_vm.summary.corporateAccountName,"readonly":true}})],1)],1),_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t('general.label.partner'),"value":_vm.summary.mspName,"readonly":true}})],1),_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t(
                'user_management.expenses_details_page.user_details_section.identification_field_label'
              ),"value":_vm.summary.identificationField,"readonly":true}})],1)],1),_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t(
                'user_management.expenses_details_page.user_details_section.user_contract_id_label'
              ),"value":_vm.summary.userContractId,"readonly":true}})],1),_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t(
                'user_management.expenses_details_page.user_details_section.corporate_contract_id_label'
              ),"value":_vm.summary.corporateAccountContractId,"readonly":true}})],1)],1)],1),_c('base-section',{attrs:{"title":_vm.$t('user_management.expenses_details_page.other_section.title')}},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t(
                'user_management.expenses_details_page.other_section.not_billed_label'
              ),"value":_vm.summary.billed ? _vm.$t('general.no') : _vm.$t('general.yes'),"readonly":true}})],1),_c('el-col',{attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$t(
                'user_management.expenses_details_page.other_section.not_settled_label'
              ),"value":_vm.summary.settled ? _vm.$t('general.no') : _vm.$t('general.yes'),"readonly":true}})],1)],1)],1),(_vm.properties.length)?_c('base-section',{attrs:{"title":_vm.$t('user_management.expenses_details_page.details_section.title')}},[_vm._l((_vm.properties),function(chunk,index){return [_c('el-row',{key:'chunk-' + index,attrs:{"gutter":24}},[_vm._l((chunk),function(property,idx){return [_c('el-col',{key:'property-' + idx,attrs:{"span":12}},[_c('base-input',{attrs:{"label":_vm.$trans(property.label),"value":property.value,"readonly":true}})],1)]})],2)]})],2):_vm._e()],1),_c('el-dialog',{attrs:{"title":_vm.$t('user_management.expenses_details_page.refund_dialog.title'),"visible":_vm.refundExpense.visible},on:{"update:visible":function($event){return _vm.$set(_vm.refundExpense, "visible", $event)},"close":function($event){_vm.refundExpense.visible = false}}},[_c('el-form',{key:_vm.refundExpense.key,attrs:{"model":_vm.refundExpense.form,"label-position":"top"}},[_c('base-input',{attrs:{"type":"textarea","label":_vm.$t(
            'user_management.expenses_details_page.refund_dialog.remark_label'
          ),"maxlength":"140","autosize":{ minRows: 5, maxRows: 8 },"show-word-limit":true,"placeholder":_vm.$t(
            'user_management.view_app_users_page.add_expense.input_description.placeholder',
            { number: 140 }
          ),"max-width":400},model:{value:(_vm.refundExpense.form.reason),callback:function ($$v) {_vm.$set(_vm.refundExpense.form, "reason", $$v)},expression:"refundExpense.form.reason"}})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('base-button',{attrs:{"label":_vm.$t('general.cancel')},on:{"click":function($event){_vm.refundExpense.visible = false}}}),_c('base-button',{attrs:{"type":"primary","label":_vm.$t(
            'user_management.expenses_details_page.refund_dialog.submit_button'
          )},on:{"click":_vm.refund}})],1)],1),_c('el-dialog',{attrs:{"title":_vm.$t('user_management.expenses_details_page.correct_dialog.title'),"visible":_vm.correctExpense.visible},on:{"update:visible":function($event){return _vm.$set(_vm.correctExpense, "visible", $event)},"close":function($event){_vm.correctExpense.visible = false}}},[_c('el-form',{key:_vm.correctExpense.key,attrs:{"model":_vm.correctExpense.form,"label-position":"top"}},[_c('base-radio',{staticStyle:{"margin-bottom":"-5px"},attrs:{"label":'ADD',"value":_vm.correctExpense.form.correctType},on:{"input":_vm.changeCorrectType}},[_vm._v(" "+_vm._s(_vm.$t( 'user_management.expenses_details_page.correct_dialog.add_cost_label' ))+" ")]),_c('base-radio',{attrs:{"label":'SUBTRACT',"value":_vm.correctExpense.form.correctType},on:{"input":_vm.changeCorrectType}},[_vm._v(" "+_vm._s(_vm.$t( 'user_management.expenses_details_page.correct_dialog.refund_cost_label' ))+" ")]),_c('el-row',{staticStyle:{"max-width":"424px"},attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":12}},[_c('base-currency-input',{attrs:{"label":_vm.$t('general.label.price_vat_incl'),"validations":_vm.$v.correctExpense.form.amountVatIncl},model:{value:(_vm.correctExpense.form.amountVatIncl),callback:function ($$v) {_vm.$set(_vm.correctExpense.form, "amountVatIncl", $$v)},expression:"correctExpense.form.amountVatIncl"}})],1),_c('el-col',{attrs:{"span":12}},[_c('base-select',{ref:"vatPercentage",attrs:{"label":_vm.$t(
                'user_management.view_app_users_page.add_expense.input_vat_perc.label'
              ),"required":true,"validations":_vm.$v.correctExpense.form.vatPercentage,"prop":"vatPercentage","items":_vm.vatPercentages,"name":"vatPercentage"},model:{value:(_vm.correctExpense.form.vatPercentage),callback:function ($$v) {_vm.$set(_vm.correctExpense.form, "vatPercentage", $$v)},expression:"correctExpense.form.vatPercentage"}})],1)],1),_c('base-input',{attrs:{"type":"textarea","label":_vm.$t(
            'user_management.expenses_details_page.correct_dialog.remark_label'
          ),"maxlength":"140","autosize":{ minRows: 5, maxRows: 8 },"show-word-limit":true,"placeholder":_vm.$t(
            'user_management.view_app_users_page.add_expense.input_description.placeholder',
            { number: 140 }
          ),"max-width":400},model:{value:(_vm.correctExpense.form.remark),callback:function ($$v) {_vm.$set(_vm.correctExpense.form, "remark", $$v)},expression:"correctExpense.form.remark"}})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('base-button',{attrs:{"label":_vm.$t('general.cancel')},on:{"click":function($event){_vm.correctExpense.visible = false}}}),_c('base-button',{attrs:{"type":"primary","label":_vm.$t(
            'user_management.expenses_details_page.correct_dialog.submit_button'
          )},on:{"click":_vm.correct}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }