<template>
  <page-layout
    :title="$t('finance.company_budget_page.title')"
    id="companyBudgetPage"
    v-loading="loading"
  >
    <template #header-actions v-if="corporateAccount && corporateAccount.isPrepaid">
      <base-button
        v-if="$auth.isOspAdmin()"
        :label="$t('finance.company_budget_page.button_add_transaction_label')"
        @click="dialogAddTransaction.visible = true"
      >
      </base-button>
      <base-button
        :label="$t('general.button_label.save_changes')"
        type="primary"
        :loading="saving"
        @click="save"
      >
      </base-button>
    </template>

    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('finance.company_budget_page.alert_text')" />
    </el-alert>
    <partner-corporate-account-filter
      :allowSelectPartner="$auth.isOspAdmin()"
      :allowSelectCA="$auth.isMsp() && $auth.partner.isArtemis"
      v-model="corporateAccountOspId"
    ></partner-corporate-account-filter>

    <el-form
      v-if="corporateAccount && corporateAccount.showBudgetDetails"
      :key="key"
      label-position="top"
      :model="form"
    >
      <base-section :title="$t('finance.company_budget_page.budget_details_section.title')">
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item
              id="remainingBudget"
              :label="
                $t(
                  'finance.company_budget_page.budget_details_section.input_remaining_budget_label'
                )
              "
              label-position="top"
            >
              <p class="remaining-budget">
                € <span class="remaining-budget__amount">{{ form.currentAmount }}</span>
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <base-input
              :key="form.vatIncludedInt"
              v-model="vatIncludedOptions[form.vatIncludedInt]"
              :disabled="true"
              :label="
                $t('finance.company_budget_page.budget_details_section.input_vat_include_label')
              "
              ref="vatInclude"
              prop="vatInclude"
              name="vatInclude"
            ></base-input>
          </el-col>
        </el-row>
        <el-row v-if="corporateAccount.isPrepaid" :gutter="40">
          <el-col :span="12">
            <base-currency-input
              v-model="form.threshold"
              :label="
                $t('finance.company_budget_page.budget_details_section.input_threshold_label')
              "
              :info="$t('finance.company_budget_page.budget_details_section.input_threshold_info')"
            >
            </base-currency-input>
          </el-col>
        </el-row>
      </base-section>
      <base-section
        v-if="corporateAccount.isPrepaid"
        :title="$t('finance.company_budget_page.payment_details_section.title')"
      >
        <el-row :gutter="40">
          <el-col :span="12">
            <base-input
              :key="form.budgetPaymentReference"
              :value="form.budgetPaymentReference"
              :disabled="true"
              :label="
                $t(
                  'finance.company_budget_page.payment_details_section.input_payment_reference_label'
                )
              "
            >
            </base-input>
          </el-col>
          <el-col :span="12">
            <base-input
              :key="form.iban"
              :value="$t('general.olympus_bank_account')"
              :disabled="true"
              :label="$t('finance.company_budget_page.payment_details_section.input_account_label')"
              v-mask="bankAccountMask"
            >
            </base-input>
          </el-col>
        </el-row>
      </base-section>
      <base-section
        v-else-if="corporateAccount.isPostpaidWithSecurityDeposit"
        id="securityDeposit"
        :title="$t('finance.company_budget_page.security_deposit_section.title')"
      >
        <p class="remaining-budget">
          €
          <span class="remaining-budget__amount">{{ companyBudget.securityDepositAmount }}</span>
        </p>
      </base-section>
    </el-form>

    <div class="budget-details" v-else-if="corporateAccount">
      <p class="base-section__title">
        {{ $t('finance.company_budget_page.budget_details.title') }}
      </p>
      <p class="budget-derails__content">
        {{ $t('finance.company_budget_page.budget_details.text') }}
      </p>
    </div>

    <el-dialog
      :title="$t('finance.company_budget_page.dialog_add_transaction.title', 'Add transaction')"
      :close-on-click-modal="false"
      :visible.sync="dialogAddTransaction.visible"
      @close="dialogAddTransactionClose"
    >
      <el-form
        :key="dialogAddTransaction.key"
        :model="dialogAddTransaction.form"
        label-position="top"
        @submit="() => null"
      >
        <base-select
          v-if="$auth.isOspAdmin()"
          :label="$t('general.label.type')"
          ref="transactionTypes"
          prop="transactionTypes"
          :items="transactionTypes"
          v-model="dialogAddTransaction.form.transactionType"
          name="transactionTypes"
        />
        <base-currency-input
          :label="$t('general.input_label.amount')"
          allowNegative
          v-model="dialogAddTransaction.form.amount"
        ></base-currency-input>
        <base-input
          type="textarea"
          :label="$t('general.input_label.description')"
          maxlength="140"
          :autosize="{ minRows: 5, maxRows: 8 }"
          v-model.trim="dialogAddTransaction.form.description"
          prop="description"
          name="description"
          :show-word-limit="true"
          :placeholder="
            $t('user_management.view_app_users_page.add_expense.input_description.placeholder', {
              number: 140
            })
          "
          :required="true"
        />
      </el-form>
      <span slot="footer" class="dialog-footer">
        <base-button :label="$t('general.cancel')" @click="dialogAddTransaction.visible = false" />
        <base-button
          :loading="dialogAddTransaction.updating"
          type="primary"
          :disabled="!dialogAddTransaction.form.amount || !dialogAddTransaction.form.description"
          :label="$t('general.confirm')"
          @click="addTransaction"
        />
      </span>
    </el-dialog>
  </page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'
import PartnerCorporateAccountFilter from '@/components/PartnerCorporateAccountFilter'
import CorporateAccountRepository from '@/app/repositories/CorporateAccountRepository'

import BudgetRepository from '@/app/repositories/BudgetRepository'
import CompanyBudget from '@/app/models/CompanyBudget'

const corporateAccountRepository = new CorporateAccountRepository()
const companyBudgetRepository = new BudgetRepository(CompanyBudget)

const dialogAddTransactionDefaultConfig = () => {
  return {
    visible: false,
    form: {
      mobilityBudget: { ospId: null },
      transportMode: 'NONE',
      transactionType: 'MANUAL_UPDATE',
      amount: null,
      description: null
    },
    key: '',
    updating: false,
    saving: false
  }
}

export default {
  name: 'CompanyBudget',
  components: { PageLayout, PartnerCorporateAccountFilter },
  data () {
    return {
      dialogAddTransaction: dialogAddTransactionDefaultConfig(),
      corporateAccount: null,
      corporateAccountOspId: null,
      companyBudget: {},
      loading: false,
      saving: false,
      key: '',
      isPrepaidCompany: true,
      form: {
        budgetPaymentReference: '',
        iban: '',
        remaining: null,
        vatInclude: null,
        threshold: null,
        paymentReference: null,
        account: null
      },
      bankAccountMask: {
        mask: '#### #### #### ####',
        tokens: {
          '#': { pattern: /[a-zA-Z0-9]/, transform: v => v.toUpperCase() }
        }
      },
      vatIncludedOptions: {
        1: this.$t('general.yes'),
        0: this.$t('general.no')
      },
      transactionTypes: [
        {
          value: 'MANUAL_UPDATE',
          label: this.$t('general.transaction_type.manual_update')
        },
        {
          value: 'TOPUP',
          label: this.$t('general.transaction_type.topup')
        }
      ],
      securityDepositAmount: 0
    }
  },
  watch: {
    corporateAccountOspId (v) {
      if (v) {
        this.fetchCorporateBudget()
      }
    }
  },
  methods: {
    addTransaction () {
      this.dialogAddTransaction.form.mobilityBudget.ospId = this.companyBudget.ospId
      this.dialogAddTransaction.updating = true
      this.$api.budget.addTransactionForCorporateAccount(this.dialogAddTransaction.form, {
        successNotification: this.$t(
          'finance.company_budget_page.dialog_add_transaction.success_notification'
        ),
        onSuccess: () => {
          this.dialogAddTransaction = dialogAddTransactionDefaultConfig()
          this.fetchCorporateBudget()
        },
        onError: () => {
          this.dialogAddTransaction.updating = false
        }
      })
    },
    dialogAddTransactionClose () {
      this.dialogAddTransaction = dialogAddTransactionDefaultConfig()
      this.dialogAddTransaction.key = this.$key()
    },
    save () {
      this.saving = true
      this.companyBudget.data.threshold = this.form.threshold
      this.companyBudget.save({
        successNotification: this.$t('finance.company_budget_page.success_notification'),
        onFinally: () => {
          this.saving = false
        }
      })
    },
    fetchCorporateBudget () {
      this.loading = true
      const promises = []
      promises.push(
        corporateAccountRepository.getData(this.corporateAccountOspId, {
          onSuccess: corporateAccount => {
            // console.log(corporateAccount)
            this.corporateAccount = corporateAccount
            this.form.budgetPaymentReference =
              corporateAccount.corporateAccount.data.budgetPaymentReference
            this.form.iban = corporateAccount.corporateAccount.data.iban
          }
        })
      )
      promises.push(
        companyBudgetRepository.getByOwner(
          {
            budgetType: 'COMPANY_BUDGET',
            ownerOspId: this.corporateAccountOspId
          },
          {
            onSuccess: response => {
              if (response.length) {
                const [companyBudget] = response
                // console.log(companyBudget)
                this.companyBudget = companyBudget
                this.form.threshold = companyBudget.threshold
                this.form.vatIncludedInt = companyBudget.vatIncludedInt
                this.form.currentAmount = companyBudget.currentAmount
              }
            }
          }
        )
      )
      Promise.all(promises).then(
        () => {
          this.loading = false
          this.key = this.$key()
        },
        () => {
          this.loading = false
          this.key = this.$key()
        }
      )
    }
  }
}
</script>
