<template>
  <page-layout
    :title="$t('finance.transactions_page.title')"
    id="companyAllTransactionsPage"
    :loading="loading"
    :key="key"
    :fixed-footer="!!totalPages && totalPages > 1"
  >
    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('finance.transactions_page.alert')" />
    </el-alert>

    <base-section class="filters">
      <div class="filters--content">
        <el-form label-position="top" :model="form" >
          <el-row :gutter="24">
            <el-col :span="12">
              <base-datepicker
                :label="$t('general.label.start_date')"
                v-model="form.from"
              ></base-datepicker>
            </el-col>

            <el-col :span="12">
              <base-datepicker
                :label="$t('general.label.end_date')"
                v-model="form.to"
              ></base-datepicker>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <base-input
                ref="firstName"
                prop="firstName"
                v-model="form.firstName"
                name="firstName"
                auto-complete="off"
                :label="$t('general.label.first_name')"
              ></base-input>
            </el-col>

            <el-col :span="12">
              <base-input
                ref="lastName"
                prop="lastName"
                v-model="form.lastName"
                name="lastName"
                auto-complete="off"
                :label="$t('general.label.last_name')"
              ></base-input>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <base-select
                :label="$t('resources.mobility_select.mrp.label')"
                :items="getMrpPartners"
                v-model="form.mrpOspId"
                clearable
                filterable
              >
                <template v-slot:option="{ items }">
                  <el-option
                    v-for="item in items"
                    :key="item.extId"
                    :value="item.ospId"
                    :label="item.name"
                  >
                    <div class="filters__select-option">
                      <i class="ri-building-4-line"></i>
                      {{ item.name }}
                    </div>
                  </el-option>
                </template>
              </base-select>
            </el-col>

            <el-col :span="12">
              <base-select
                :label="$t('resources.mobility_select.mode.label')"
                :items="getMobilityModes"
                v-model="form.mobilityMode"
                clearable
                filterable
              >
                <template v-slot:option="{ items }">
                  <el-option
                    v-for="item in items"
                    :key="item.mobilityMode"
                    :value="item.mobilityMode"
                    :label="$trans(item.label) || item.mobilityMode"
                  >
                    <div class="filters__select-option">
                      <i class="ri-building-4-line"></i>
                      {{ $trans(item.label) || item.mobilityMode }}
                    </div>
                  </el-option>
                </template>
              </base-select>
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <el-col :span="12">
              <base-select
                :label="$t('finance.transactions_page.transactions_table.transaction_type')"
                :items="transactionTypeItems"
                v-model="form.transactionType"
                clearable
                filterable
              >
                <template v-slot:option="{ items }">
                  <el-option
                    v-for="item in items"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  >
                  </el-option>
                </template>
              </base-select>
            </el-col>

            <el-col :span="12">
              <base-input
                ref="amount"
                prop="amount"
                type="number"
                v-model="form.amount"
                name="amount"
                auto-complete="off"
                :label="$t('general.input_label.amount')"
              ></base-input>
            </el-col>
          </el-row>

        </el-form>
        <partner-corporate-account-filter
          :allowSelectPartner="$auth.isOspAdmin()"
          :allowSelectCA="$auth.isMsp() && $auth.partner.isArtemis"
          v-model="corporateAccountOspId"
        ></partner-corporate-account-filter>
      </div>
      <el-dropdown trigger="click" class="download-all" style="padding: 8px 0">
        <base-button
          type="primary"
          append-icon="ri-arrow-down-s-line"
          prepend-icon="ri-download-2-line"
          :label="$t('general.label.download_all')"
        />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="downloadXlsx">
            <i class="ri-download-2-line"></i>
            {{ $t('general.label.download') }} XLSX
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </base-section>
    <base-section
      :is-flex="false"
      :title="$t('finance.transactions_page.transactions_section.title')"
      class="table-wrapper"
    >
      <el-table
        id="expensesTable"
        ref="table"
        :data.sync="tableData"
        :empty-text="$t('general.empty_table')"
        @row-click="handleRowClick"
      >
        <el-table-column
          prop="mobilityMode"
          min-width="100"
          show-overflow-tooltip
          :label="
            $t('finance.transactions_page.transactions_table.mobility_mode')
          "
        >
        </el-table-column>
        <el-table-column
          prop="dateTime"
          width="130"
          :label="$t('general.input_label.date')"
        >
        </el-table-column>
        <el-table-column
          prop="currencyAmount"
          min-width="80"
          class-name="text-right"
          :label="$t('general.input_label.amount')"
        >
          <template slot-scope="{ row }">
            <span v-html="row.currencyAmount"></span>
          </template>
        </el-table-column>
        <el-table-column
          prop="transactionTypeLabel"
          min-width="100"
          :label="
            $t('finance.transactions_page.transactions_table.transaction_type')
          "
        >
        </el-table-column>
        <el-table-column
          prop="description"
          min-width="120"
          :label="$t('general.input_label.description')"
        >
        </el-table-column>
        <el-table-column
          prop="description"
          min-width="80"
          :label="$t('general.input_label.name')"
        >
         <template slot-scope="{ row }">
            {{ `${row.firstName || ''} ${row.lastName || ''}`}}
          </template>
        </el-table-column>
        <el-table-column
          prop="mrpName"
          min-width="80"
          :label="$t('resources.mobility_select.mrp.label')"
        >
        </el-table-column>

        <el-table-column
          prop="createdOn"
          min-width="100"
          :label="$t('user_management.view_app_users_page.budget.transactions.table.created_on')"
        >
          <template slot-scope="{ row }">
            {{ $date.fromFormat(row.createdOn, DATE_TIME_ISO).toFormat(DATE_FORMAT) }}
          </template>
        </el-table-column>

        <el-table-column
          prop="bookedFor"
          min-width="100"
          :label="$t('general.input_label.booked_for')"
        >
          <template slot-scope="{ row }">
            {{ $date.fromFormat(row.bookedFor, DATE_TIME_ISO).toFormat(DATE_FORMAT) }}
          </template>
        </el-table-column>
      </el-table>
    </base-section>
    <template #footer v-if="totalPages && totalPages > 1">
      <base-pagination
        :key="'pagination_' + $route.name"
        v-model="pagination.page"
        :pages="totalPages"
        @input="fetchPage"
      />
    </template>
  </page-layout>
</template>

<script>
import { debounce, mapValues, toNumber, orderBy } from 'lodash'
import PageLayout from '../layouts/PageLayout'
import { mapGetters, mapActions } from 'vuex'
import CompanyTransaction from '@/app/models/CompanyTransaction'
import TableLoader from '@/mixins/TableLoader'
import CancelRequest from '@/mixins/CancelRequest'
import FinancePages from '@/mixins/FinancePages'
import PartnerCorporateAccountFilter from '@/components/PartnerCorporateAccountFilter'
import store from '@/store'
// eslint-disable-next-line no-unused-vars
import { DATE_TIME_FORMAT, DATE_TIME_ISO, DATE_FORMAT, processUploadResponse } from '../app/helpers'

export default {
  name: 'CompanyAllTransactions',
  components: { PageLayout, PartnerCorporateAccountFilter },
  mixins: [CancelRequest, TableLoader, FinancePages],
  data () {
    return {
      DATE_TIME_FORMAT,
      DATE_TIME_ISO,
      DATE_FORMAT,
      loading: true,
      saving: false,
      key: '',
      totalPages: 0,
      form: {
        from: this.$date.local().startOf('month').toISODate(),
        to: this.$date.local().endOf('day').toISODate(),
        firstName: null,
        lastName: null,
        mrpOspId: null,
        mobilityMode: null,
        transactionType: null,
        amount: null
      },
      corporateAccountOspId: null,
      pagination: {
        page: 0,
        size: 15,
        sort: null
      },
      tableData: [],
      transactionTypes: orderBy(['TOPUP', 'MANUAL_UPDATE', 'START_AMOUNT', 'REFUND', 'EVENT', 'TRANSFER', 'AUTOMATIC_UPDATE_RESET', 'AUTOMATIC_UPDATE_ADD', 'USER_FEE'])
    }
  },
  computed: {
    ...mapGetters({
      getMobilityModes: 'resources/getMobilityModes',
      getMrpPartners: 'resources/getMrpPartners',
      getCompanyBudget: 'budget/getCompanyBudget',
      corporateAccount: 'corporateAccount/corporateAccount'
    }),
    companyBudget () {
      return this.getCompanyBudget(this.corporateAccountOspId)
    },
    filter () {
      return {
        ...mapValues(this.form, v => v || null),
        amount: this.form.amount ? toNumber(this.form.amount) : null,
        from: this.$date.fromISO(this.form.from).toFormat(DATE_TIME_ISO),
        to: this.$date.fromISO(this.form.to).plus({ days: 1 }).toFormat(DATE_TIME_ISO),
        budgetOspId: { ospId: this.companyBudget.ospId },
        // eventTimestampFrom: this.$date.fromISO(this.form.date).startOf('month').toFormat(DATE_TIME_ISO),
        // eventTimestampTo: this.$date.fromISO(this.form.date).endOf('month').toFormat(DATE_TIME_ISO),
        pagination: {
          page: this.pagination.page,
          size: this.pagination.size
        }
      }
    },
    transactionTypeItems () {
      const items = []
      this.transactionTypes.forEach(v => {
        items.push({
          label: this.$t(`general.transaction_type.${v.toLowerCase()}`),
          value: v
        })
      })
      return items
    }
  },
  watch: {
    corporateAccountOspId: function (v) {
      if (v) {
        this.fetchCorporateBudget()
      }
    },
    form: {
      deep: true,
      handler: debounce(function () {
        if (this.form.from && this.form.to) {
          this.fetchData()
        }
      }, 700)
    }
  },
  methods: {
    ...mapActions({
      fetchCompanyBudget: 'budget/getCompanyBudget',
      fetchAllMrp: 'resources/fetchAllMrp',
      fetchMobilityModes: 'resources/fetchMobilityModes'
    }),
    fetchPage (page) {
      this.pagination.page = page
      this.fetchData()
    },
    async fetchCorporateBudget () {
      await this.fetchCompanyBudget({ ospId: this.corporateAccountOspId })
      this.fetchData()
      return undefined
    },
    fetchData () {
      this.TableLoader_show()
      return this.$api.budget.getTransactionsByFilter(this.filter, {
        onSuccess: ({ totalPages, content }) => {
          this.totalPages = totalPages
          this.tableData = content.map(v => new CompanyTransaction(v))
        },
        onFinally: () => {
          this.TableLoader_hide()
        }
      })
    },
    save () {
      this.saving = true
      this.companyBudget.save({
        onFinally: () => {
          this.saving = false
        }
      })
    },
    async fetchTransactionData (eventOspId) {
      try {
        await this.$api.event.get(
          { ospId: eventOspId },
          {
            onSuccess: transaction => {
              store.dispatch('budget/getTransactionDetails', transaction)
            }
          }
        )
      } catch (e) {
        console.error(e)
      }
    },
    handleRowClick ({ eventOspId }) {
      if (!eventOspId) {
        return
      }
      this.fetchTransactionData(eventOspId)
      this.$router.push({
        name: 'transactionDetails',
        params: {
          eventOspId
        },
        query: this.$route.query
      })
    },
    downloadXlsx () {
      return this.$api.budget.downloadAllXlsx(this.filter, {
        onSuccess: response => processUploadResponse(response)
      })
    }
  },
  mounted () {
    this.fetchAllMrp()
    this.fetchMobilityModes()
  }
}
</script>
