<template>
  <page-layout :title="$t('finance.invoices_page.title')" id="invoicesPage" :fixed-footer="true">
    <base-section class="filters">
      <div class="filters--content">
        <el-form label-position="top" :model="filter" :inline="true">
          <base-datepicker
            :label="$t('general.label.start_date')"
            v-model="filter.invoiceMonthFrom"
          ></base-datepicker>
          <base-datepicker
            :label="$t('general.label.end_date')"
            v-model="filter.invoiceMonthTo"
          ></base-datepicker>
          <br />
          <base-select
            v-role="['ospadmin']"
            v-model="filter.status"
            :label="$t('general.label.status')"
            ref="status"
            prop="status"
            :items="statuses"
            :clearable="true"
            name="status"
            :max-width="361"
          ></base-select>
        </el-form>
        <partner-corporate-account-filter
          :allowSelectPartner="$auth.isOspAdmin()"
          :allowSelectCA="$auth.isMsp() && $auth.partner.isArtemis"
          :partner.sync="filter.mspOspId"
          clearableCA
          clearablePartner
          v-model="filter.corporateAccountOspId"
          @mounted="filtersMounted = true"
        ></partner-corporate-account-filter>
      </div>
    </base-section>
    <el-dropdown trigger="click" class="download-all" v-role="['ospadmin']">
      <base-button
        type="primary"
        append-icon="ri-arrow-down-s-line"
        prepend-icon="ri-download-2-line"
        :label="$t('general.label.download_all')"
      />
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="downloadAllPdf">
          <i class="ri-download-2-line"></i>
          {{ $t('general.label.download') }} PDF
        </el-dropdown-item>
        <el-dropdown-item @click.native="downloadAllXml">
          <i class="ri-download-2-line"></i>
          {{ $t('general.label.download') }} XML
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <base-section class="table-wrapper">
      <el-table
        id="invoicesTable"
        ref="table"
        :data.sync="tableData"
        :empty-text="$t('general.empty_table')"
        row-class-name="table-row"
        style="width: 100%"
      >
        <el-table-column prop="icon" width="37">
          <template>
            <i class="ri-file-chart-line"></i>
          </template>
        </el-table-column>
        <el-table-column
          class-name="text-bold"
          prop="year"
          :label="$t('general.label.year')"
          width="60"
        >
          <template slot-scope="{ row }">
            {{ $date.fromFormat(row.invoiceMonth, 'yyyyMM').toFormat('yyyy') }}
          </template>
        </el-table-column>
        <el-table-column
          class-name="text-bold"
          prop="month"
          :label="$t('general.label.month')"
          min-width="80"
        >
          <template slot-scope="{ row }">
            {{ $date.fromFormat(row.invoiceMonth, 'yyyyMM').toFormat('MMMM') }}
          </template>
        </el-table-column>
        <el-table-column
          class-name="text-bold"
          prop="invoiceNumber"
          :label="$t('finance.company_budget_page.table_header.invoice_number')"
          min-width="110"
        ></el-table-column>
        <el-table-column
          class-name="text-bold"
          prop="structuredPaymentReference"
          :label="$t('finance.company_budget_page.table_header.payment_reference')"
          min-width="140"
        ></el-table-column>
        <el-table-column
          class-name="text-bold"
          prop="corporateAccountName"
          :label="$t('finance.company_budget_page.table_header.corporateAccountName')"
          min-width="140"
        ></el-table-column>
        <el-table-column
          class-name="text-bold"
          prop="mspName"
          :label="$t('finance.company_budget_page.table_header.mspName')"
          min-width="140"
        ></el-table-column>

        <el-table-column
          v-if="$auth.hasRole('ospadmin')"
          class-name="text-bold"
          prop="status"
          :label="$t('general.label.status')"
          min-width="110"
        >
          <template slot-scope="{ row }">
            {{ $t('finance.invoices_page.invoice_status.' + row.status.toLowerCase()) }}
          </template>
        </el-table-column>
        <el-table-column
          class-name="text-bold"
          prop="amount_vat_excl"
          :label="$t('finance.company_budget_page.table_header.amount_vat_excl')"
          min-width="85"
        >
          <template slot-scope="{ row }">{{ $currency(row.totalPriceVatExcl) }}</template>
        </el-table-column>
        <el-table-column
          class-name="text-bold"
          prop="amount_vat_incl"
          :label="$t('finance.company_budget_page.table_header.amount_vat_incl')"
          min-width="85"
        >
          <template slot-scope="{ row }">{{ $currency(row.totalPriceVatIncl) }}</template>
        </el-table-column>
        <el-table-column v-if="$auth.isOspAdmin()" fixed="right" width="165">
          <template slot-scope="{ row }">
            <base-button
              size="small"
              :label="$t('invoices.add_payment.button')"
              class="add-payment-button"
              @click="handleSetPaymentValue(row)"
            />
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="175">
          <template slot-scope="{ row }">
            <el-dropdown trigger="click" class="download-invoice">
              <base-button
                size="small"
                append-icon="ri-arrow-down-s-line"
                prepend-icon="ri-download-2-line"
                :label="$t('finance.company_budget_page.button_label.download_invoice')"
              />
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="downloadPdf(row.ospId)">
                  <i class="ri-download-2-line"></i>
                  {{ $t('general.label.download') }} PDF
                </el-dropdown-item>
                <el-dropdown-item @click.native="downloadXml(row.ospId)">
                  <i class="ri-download-2-line"></i>
                  {{ $t('general.label.download') }} XML
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </base-section>
    <template #footer v-if="totalPages > 1">
      <base-pagination v-model="filter.pagination.page" :pages="totalPages" />
    </template>
    <el-dialog
      :visible="isPaymentModalVisible"
      :title="$t('invoices.add_payment.title')"
      :show-close="false"
    >
      <el-form :key="key">
        <base-input
          :label="$t('invoices.add_payment.input')"
          v-model="paymentForm.amountReceived"
        />
      </el-form>
      <template slot="footer" class="dialog-footer">
        <base-button :label="$t('general.cancel')" @click="toggleShowPaymentModal"></base-button>
        <base-button type="primary" :label="$t('general.label.add')" @click="handleAddPayment" />
      </template>
    </el-dialog>
  </page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'
import InvoiceRepository from '../app/repositories/InvoiceRepository'
import CancelRequest from '@/mixins/CancelRequest'
import TableLoader from '@/mixins/TableLoader'
import PartnerCorporateAccountFilter from '@/components/PartnerCorporateAccountFilter'

const invoiceRepository = new InvoiceRepository()

export default {
  name: 'Invoices',
  components: {
    PageLayout,
    PartnerCorporateAccountFilter
  },
  mixins: [CancelRequest, TableLoader],
  data () {
    return {
      filter: {
        mspOspId: '',
        corporateAccountOspId: '',
        invoiceMonthFrom: this.$date.local().minus({ month: 1 }).toISODate(),
        invoiceMonthTo: this.$date.local().toISODate(),
        status: null,
        pagination: {
          page: 0,
          size: 15
        }
      },
      statuses: [
        {
          value: 'CREATED',
          label: this.$t('finance.invoices_page.invoice_status.created')
        },
        {
          value: 'SENT',
          label: this.$t('finance.invoices_page.invoice_status.sent')
        },
        {
          value: 'PAID',
          label: this.$t('finance.invoices_page.invoice_status.paid')
        },
        {
          value: 'INSUFFICIENT_PAYMENT',
          label: this.$t('finance.invoices_page.invoice_status.insufficient_payment')
        },
        {
          value: 'EXCESSIVE_PAYMENT',
          label: this.$t('finance.invoices_page.invoice_status.excessive_payment')
        }
      ],
      tableData: [],
      totalPages: 1,
      filtersMounted: false,
      isPaymentModalVisible: false,
      key: '',
      paymentForm: {
        amountReceived: null,
        invoiceOspId: null
      }
    }
  },
  watch: {
    filter: {
      deep: true,
      handler () {
        this.fetchData()
      }
    },
    'filter.status' () {
      this.filter.status = this.filter.status === '' ? null : this.filter.status
    }
  },
  methods: {
    fetchData () {
      if (!this.filtersMounted) {
        return false
      }
      this.TableLoader_show()
      try {
        invoiceRepository.getByFilter(
          {
            ...this.filter,
            corporateAccountOspId: this.filter.corporateAccountOspId || undefined,
            status: this.filter.status
          },
          {
            cancelToken: this.CancelRequest_token(),
            onSuccess: this.setData
          }
        )
      } catch (e) {
        console.error(e)
      }
      this.TableLoader_hide()
    },
    setData (data) {
      this.tableData = data.content
      this.totalPages = data.totalPages
    },
    downloadAllPdf () {
      invoiceRepository.downloadAllPdf(this.filter)
    },
    downloadAllXml () {
      invoiceRepository.downloadAllXml(this.filter)
    },
    downloadPdf (ospId) {
      invoiceRepository.downloadInvoicePdf(ospId)
    },
    downloadXml (ospId) {
      invoiceRepository.downloadInvoiceXml(ospId)
    },
    toggleShowPaymentModal () {
      this.isPaymentModalVisible = !this.isPaymentModalVisible
    },
    handleSetPaymentValue (row) {
      this.toggleShowPaymentModal()
      this.paymentForm = {
        amountReceived: row.totalPriceVatIncl,
        invoiceOspId: row.ospId
      }
      this.key = this.$key()
    },
    handleAddPayment () {
      this.$api.invoices.pay(
        { ...this.paymentForm },
        {
          successNotification: this.$t('inovices.add_payment.notification'),
          onSuccess: () => {
            this.toggleShowPaymentModal()
            this.fetchData()
          }
        }
      )
    }
  },
  mounted () {
    this.filter.status =
      this.filter.status &&
      this.filter.status !== 'ANY' &&
      !(
        (this.$auth.user().isMsp || this.$auth.hasRole('user_manager')) &&
        !this.$auth.hasRole('ospadmin')
      )
        ? this.filter.status
        : undefined
  }
}
</script>

<style lang="scss" scoped>
.add-payment-button {
  width: 145px;
}
</style>
